const MINISTRYGROUPS = {
    AEST : "AEST Ministry Team",
    PSE : "PSE Ministry Team",
    AGR : "AGR Ministry Team",
    BRD : "BRD Ministry Team",
    CAS : "CAS Ministry Team",
    CAF : "CAF Ministry Team",
    MCF : "MCF Ministry Team",
    CLB : "CLB Ministry Team",
    CITZ : "CITZ Ministry Team",
    DAS : "DAS Ministry Team",
    EAO : "EAO Ministry Team",
    EDU : "EDU Ministry Team",
    ECC : "ECC Ministry Team",
    EMBC : "EMBC Ministry Team",
    EMC : "EMC Ministry Team",
    EMLI : "EMLI Ministry Team",
    FIN : "FIN Ministry Team",
    FOR : "FOR Ministry Team",
    GCP : "GCP Ministry Team",
    HTH : "HTH Ministry Team",
    IIO : "IIO Ministry Team",
    IRR : "IRR Ministry Team",
    JERI : "JERI Ministry Team",
    JED : "JED Ministry Team",
    LBR : "LBR Ministry Team",
    LDB : "LDB Ministry Team",
    LWR : "LWR Ministry Team",
    WLR : "WLR Ministry Team",
    AG : "AG Ministry Team",
    MGC : "MGC Ministry Team",
    MMHA : "MMHA Ministry Team",
    MUNI : "MUNI Ministry Team",
    ENV : "ENV Ministry Team",
    MSD : "MSD Ministry Team",
    OBC : "OBC Ministry Team",
    OCC : "OCC Ministry Team",
    OOP : "OOP Ministry Team",
    PSA : "PSA Ministry Team",
    PSSG : "PSSG Ministry Team",
    TACS : "TACS Ministry Team",
    TIC : "TIC Ministry Team",
    TRAN : "TRAN Ministry Team",
    COR : "COR Ministry Team",
    HSG : "HSG Ministry Team"

  };

  export default MINISTRYGROUPS;  