const FOI_ACTION_CONSTANTS = {
  ERROR: "ERROR",
  IS_LOADING: "IS_LOADING",
  IS_RECORDS_LOADING: "IS_RECORDS_LOADING",
  IS_EVENTS_LOADING: "IS_EVENTS_LOADING",
  QUEUE_FILTER: "QUEUE_FILTER",
  QUEUE_PARAMS: "QUEUE_PARAMS",
  EVENT_QUEUE_FILTER: "EVENT_QUEUE_FILTER",
  EVENT_QUEUE_PARAMS: "EVENT_QUEUE_PARAMS",
  SHOW_EVENT_QUEUE: "SHOW_EVENT_QUEUE",
  SHOW_ADVANCED_SEARCH: "SHOW_ADVANCED_SEARCH",
  FOI_ADVANCED_SEARCH_PARAMS: "FOI_ADVANCED_SEARCH_PARAMS",
  IS_ASSIGNEDTOLIST_LOADING: "IS_ASSIGNEDTOLIST_LOADING",
  IS_ATTACHMENTLIST_LOADING: "IS_ATTACHMENTLIST_LOADING",
  IS_COMMENTTAGLIST_LOADING: "IS_COMMENTTAGLIST_LOADING",
  FOI_CATEGORYLIST: "FOI_CATEGORYLIST",
  FOI_COUNTRYLIST: "FOI_COUNTRYLIST",
  FOI_PROVINCELIST: "FOI_PROVINCELIST",
  FOI_REQUEST_TYPELIST: "FOI_REQUEST_TYPELIST",
  FOI_RECEIVED_MODELIST: "FOI_RECEIVED_MODELIST",
  FOI_DELIVERY_MODELIST: "FOI_DELIVERY_MODELIST",
  FOI_ASSIGNED_TOLIST: "FOI_ASSIGNED_TOLIST",
  FOI_PROCESSING_TEAM_LIST: "FOI_PROCESSING_TEAM_LIST",
  FOI_FULL_ASSIGNED_TOLIST: "FOI_FULL_ASSIGNED_TOLIST",
  FOI_MINISTRY_ASSIGNED_TOLIST: "FOI_MINISTRY_ASSIGNED_TOLIST",
  FOI_PROGRAM_AREALIST: "FOI_PROGRAM_AREALIST",
  FOI_PROGRAM_AREA_DIVISIONLIST: "FOI_PROGRAM_AREA_DIVISIONLIST",
  FOI_ADMIN_PROGRAM_AREALIST: "FOI_ADMIN_PROGRAM_AREALIST",

  FOI_LIST_REQUESTS: "FOI_LIST_REQUESTS",
  FOI_REQUESTS_COUNT: "FOI_REQUESTS_COUNT",

  FOI_REQUEST_DETAIL: "FOI_REQUEST_DETAIL",
  FOI_MINISTRYVIEW_REQUEST_DETAIL: "FOI_MINISTRYVIEW_REQUEST_DETAIL",
  CLEAR_REQUEST_DETAILS: "CLEAR_REQUEST_DETAILS",
  CLEAR_MINISTRYVIEWREQUEST_DETAILS: "CLEAR_MINISTRYVIEWREQUEST_DETAILS",
  FOI_REQUEST_DUE_DATE: "FOI_REQUEST_DUE_DATE",

  FOI_REQUEST_APPLICANT_PROFILE: "FOI_REQUEST_APPLICANT_PROFILE",

  FOI_IS_REQUEST_UPDATED: "FOI_IS_REQUEST_UPDATED",
  FOI_REQUEST_DESCRIPTION_HISTORY: "FOI_REQUEST_DESCRIPTION_HISTORY",
  FOI_MINISTRY_REQUESTSLIST: "FOI_MINISTRY_REQUESTSLIST",
  FOI_MINISTRY_DIVISIONALSTAGES: "FOI_MINISTRY_DIVISIONALSTAGES",
  FOI_PERSONAL_DIVISIONS_SECTIONS: "FOI_PERSONAL_DIVISIONS_SECTIONS",
  FOI_PERSONAL_SECTIONS: "FOI_PERSONAL_SECTIONS",
  FOI_PERSONAL_PEOPLE: "FOI_PERSONAL_PEOPLE",
  FOI_PERSONAL_FILETYPES: "FOI_PERSONAL_FILETYPES",
  FOI_PERSONAL_VOLUMES: "FOI_PERSONAL_VOLUMES",

  FOI_WATCHER_LIST: "FOI_WATCHER_LIST",

  CLOSING_REASONS: "CLOSING_REASONS",

  FOI_REQUEST_COMMENTS: "FOI_REQUEST_COMMENTS",

  FOI_REQUEST_ATTACHMENTS: "FOI_REQUEST_ATTACHMENTS",

  FOI_REQUEST_CFR_FORM: "FOI_REQUEST_CFR_FORM",
  FOI_REQUEST_CFR_FORM_HISTORY: "FOI_REQUEST_CFR_FORM_HISTORY",

  FOI_REQUEST_APPLICANT_CORRESPONDENCE: "FOI_REQUEST_APPLICANT_CORRESPONDENCE",
  FOI_REQUEST_APPLICANT_CORRESPONDENCE_TEMPLATES:
    "FOI_REQUEST_APPLICANT_CORRESPONDENCE_TEMPLATES",

  FOI_REQUEST_RECORDS: "FOI_REQUEST_RECORDS",

  FOI_NOTIFICATIONS: "FOI_NOTIFICATIONS",
  FOI_REQUEST_EXTENSIONS: "FOI_REQUEST_EXTENSIONS",

  FOI_OPENED_MINISTRIES: "FOI_OPENED_MINISTRIES",

  FOI_AXIS_REQUEST_IDS: "FOI_AXIS_REQUEST_IDS",

  RESUME_DEFAULT_SORTING: "RESUME_DEFAULT_SORTING",

  IS_CORRESPONDENCE_LOADING: "IS_CORRESPONDENCE_LOADING",

  FOI_SUBJECT_CODELIST: "FOI_SUBJECT_CODELIST",

  RESTRICTED_COMMENT_TAG_LIST: "RESTRICTED_COMMENT_TAG_LIST",

  RECORD_FORMATS: "RECORD_FORMATS",
  CONVERSION_FORMATS: "CONVERSION_FORMATS",

  FOI_PDF_STITCHED_RECORD_FOR_HARMS: "FOI_PDF_STITCHED_RECORD_FOR_HARMS",
  FOI_PDF_STITCHED_STATUS_FOR_HARMS: "FOI_PDF_STITCHED_STATUS_FOR_HARMS",

  FOI_LIST_EVENTS: "FOI_LIST_EVENTS",
  FOI_MINISTRY_EVENTLIST: "FOI_MINISTRY_EVENTLIST",
  FOI_PDF_STITCHED_STATUS_FOR_REDLINES: "FOI_PDF_STITCHED_STATUS_FOR_REDLINES",
  FOI_PDF_STITCHED_RECORD_FOR_REDLINES: "FOI_PDF_STITCHED_RECORD_FOR_REDLINES",
  FOI_PDF_STITCHED_STATUS_FOR_RESPONSEPACKAGE:
    "FOI_PDF_STITCHED_STATUS_FOR_RESPONSEPACKAGE",
  FOI_PDF_STITCHED_RECORD_FOR_RESPONSEPACKAGE:
    "FOI_PDF_STITCHED_RECORD_FOR_RESPONSEPACKAGE",
  FOI_PDF_STITCHED_STATUS_FOR_OIPCREDLINEREVIEW: "FOI_PDF_STITCHED_STATUS_FOR_OIPCREDLINEREVIEW",
  FOI_PDF_STITCHED_RECORD_FOR_OIPCREDLINEREVIEW: "FOI_PDF_STITCHED_RECORD_FOR_OIPCREDLINEREVIEW",
  FOI_PDF_STITCHED_STATUS_FOR_OIPCREDLINE: "FOI_PDF_STITCHED_STATUS_FOR_OIPCREDLINE",
  FOI_PDF_STITCHED_RECORD_FOR_OIPCREDLINE: "FOI_PDF_STITCHED_RECORD_FOR_OIPCREDLINE",

  OIPC_OUTCOMES: "OIPC_OUTCOMES",
  OIPC_STATUSES: "OIPC_STATUSES",
  OIPC_REVIEWTYPES: "OIPC_REVIEWTYPES",
  OIPC_INQUIRYOUTCOMES:  "OIPC_INQUIRYOUTCOMES",

  FOI_COMMENT_TYPES: "FOI_COMMENT_TYPES",
};

export default FOI_ACTION_CONSTANTS;
